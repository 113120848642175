import React, {useEffect, useState} from 'react'
import TitreRapport from './titre_rapport.js'
import GraphAleasRapport from './graphAleas_Rapport.js'
import GraphAleasNBjoursRapport from './graphAleasNbjours_Rapport.js'
import apiLink from '../../config.js'

function PageAleasPDF2({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)

  const [dataMoy_GEL_2D, setDataMoy_GEL_2D] =  React.useState()
  const [dataMoy_GEL_4D, setDataMoy_GEL_4D] =  React.useState()
  const [dataMin_GEL_2D, setDataMin_GEL_2D] =  React.useState()
  const [dataMin_GEL_4D, setDataMin_GEL_4D] =  React.useState()
  const [dataMax_GEL_2D, setDataMax_GEL_2D] =  React.useState()
  const [dataMax_GEL_4D, setDataMax_GEL_4D] =  React.useState()
  const [dataQ1_GEL_2D, setDataQ1_GEL_2D] =  React.useState()
  const [dataQ1_GEL_4D, setDataQ1_GEL_4D] =  React.useState()
  const [dataQ3_GEL_2D, setDataQ3_GEL_2D] =  React.useState()
  const [dataQ3_GEL_4D, setDataQ3_GEL_4D] =  React.useState()
  const [dataMoy_CDC_2D, setDataMoy_CDC_2D] =  React.useState()
  const [dataMoy_CDC_4D, setDataMoy_CDC_4D] =  React.useState()
  const [dataMin_CDC_2D, setDataMin_CDC_2D] =  React.useState()
  const [dataMin_CDC_4D, setDataMin_CDC_4D] =  React.useState()
  const [dataMax_CDC_2D, setDataMax_CDC_2D] =  React.useState()
  const [dataMax_CDC_4D, setDataMax_CDC_4D] =  React.useState()
  const [dataQ1_CDC_2D, setDataQ1_CDC_2D] =  React.useState()
  const [dataQ1_CDC_4D, setDataQ1_CDC_4D] =  React.useState()
  const [dataQ3_CDC_2D, setDataQ3_CDC_2D] =  React.useState()
  const [dataQ3_CDC_4D, setDataQ3_CDC_4D] =  React.useState()
  const [dataNbjoursMoy_GEL_2D, setdataNbjoursMoy_GEL_2D] =  React.useState()
  const [dataNbjoursMoy_GEL_4D, setdataNbjoursMoy_GEL_4D] =  React.useState()
  const [dataNbjoursSansRisque_GEL_2D, setdataNbjoursSansRisque_GEL_2D] =  React.useState()
  const [dataNbjoursSansRisque_GEL_4D, setdataNbjoursSansRisque_GEL_4D] =  React.useState()
  const [dataNbjoursMoy_CDC_2D, setdataNbjoursMoy_CDC_2D] =  React.useState()
  const [dataNbjoursMoy_CDC_4D, setdataNbjoursMoy_CDC_4D] =  React.useState()
  const [dataNbjoursSansRisque_CDC_2D, setdataNbjoursSansRisque_CDC_2D] =  React.useState()
  const [dataNbjoursSansRisque_CDC_4D, setdataNbjoursSansRisque_CDC_4D] =  React.useState()

  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })

    fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + idExploit)
      .then((response) => response.json())
      .then((response) => {
        if ('error' in response) {
          // Si la réponse contient une erreur, relancer la requête après 60 secondes
          setTimeout(fetchData, 60000);
        } else {
          setDataMoy_GEL_2D(response.dataMoy_GEL_2D)
          setDataMoy_GEL_2D(prev => {
            return prev;
          })
          setDataMoy_GEL_4D(response.dataMoy_GEL_4D)
          setDataMoy_GEL_4D(prev => {
            return prev;
          })
          setDataMin_GEL_2D(response.dataMin_GEL_2D)
          setDataMin_GEL_2D(prev => {
            return prev;
          })
          setDataMin_GEL_4D(response.dataMin_GEL_4D)
          setDataMin_GEL_4D(prev => {
            return prev;
          })
          setDataMax_GEL_2D(response.dataMax_GEL_2D)
          setDataMax_GEL_2D(prev => {
            return prev;
          })
          setDataMax_GEL_4D(response.dataMax_GEL_4D)
          setDataMax_GEL_4D(prev => {
            return prev;
          })
          setDataQ1_GEL_2D(response.dataQ1_GEL_2D)
          setDataQ1_GEL_2D(prev => {
            return prev;
          })
          setDataQ1_GEL_4D(response.dataQ1_GEL_4D)
          setDataQ1_GEL_4D(prev => {
            return prev;
          })
          setDataQ3_GEL_2D(response.dataQ3_GEL_2D)
          setDataQ3_GEL_2D(prev => {
            return prev;
          })
          setDataQ3_GEL_4D(response.dataQ3_GEL_4D)
          setDataQ3_GEL_4D(prev => {
            return prev;
          })
          setDataMoy_CDC_2D(response.dataMoy_CDC_2D)
          setDataMoy_CDC_2D(prev => {
            return prev;
          })
          setDataMoy_CDC_4D(response.dataMoy_CDC_4D)
          setDataMoy_CDC_4D(prev => {
            return prev;
          })
          setDataMin_CDC_2D(response.dataMin_CDC_2D)
          setDataMin_CDC_2D(prev => {
            return prev;
          })
          setDataMin_CDC_4D(response.dataMin_CDC_4D)
          setDataMin_CDC_4D(prev => {
            return prev;
          })
          setDataMax_CDC_2D(response.dataMax_CDC_2D)
          setDataMax_CDC_2D(prev => {
            return prev;
          })
          setDataMax_CDC_4D(response.dataMax_CDC_4D)
          setDataMax_CDC_4D(prev => {
            return prev;
          })
          setDataQ1_CDC_2D(response.dataQ1_CDC_2D)
          setDataQ1_CDC_2D(prev => {
            return prev;
          })
          setDataQ1_CDC_4D(response.dataQ1_CDC_4D)
          setDataQ1_CDC_4D(prev => {
            return prev;
          })
          setDataQ3_CDC_2D(response.dataQ3_CDC_2D)
          setDataQ3_CDC_2D(prev => {
            return prev;
          })
          setDataQ3_CDC_4D(response.dataQ3_CDC_4D)
          setDataQ3_CDC_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_GEL_2D(response.dataNbjoursMoy_GEL_2D)
          setdataNbjoursMoy_GEL_2D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_GEL_2D(response.dataNbjoursSansRisque_GEL_2D)
          setdataNbjoursSansRisque_GEL_2D(prev => {
            return prev;
          })
          setdataNbjoursMoy_GEL_4D(response.dataNbjoursMoy_GEL_4D)
          setdataNbjoursMoy_GEL_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_GEL_4D(response.dataNbjoursSansRisque_GEL_4D)
          setdataNbjoursSansRisque_GEL_4D(prev => {
            return prev;
          })
          setdataNbjoursMoy_CDC_2D(response.dataNbjoursMoy_CDC_2D)
          setdataNbjoursMoy_CDC_2D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_CDC_2D(response.dataNbjoursSansRisque_CDC_2D)
          setdataNbjoursSansRisque_CDC_2D(prev => {
            return prev;
          })
          setdataNbjoursMoy_CDC_4D(response.dataNbjoursMoy_CDC_4D)
          setdataNbjoursMoy_CDC_4D(prev => {
            return prev;
          })
          setdataNbjoursSansRisque_CDC_4D(response.dataNbjoursSansRisque_CDC_4D)
          setdataNbjoursSansRisque_CDC_4D(prev => {
            return prev;
          })
        }
      })
      .catch((error) => {
        // Gérer les erreurs de la requête
        console.error('Erreur lors de la requête API :', error);
      });
  }
  useEffect ( () => {
    fetchData()
  }, [idExploit])
  
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Impact des aléas climatique'} Sous_Titre={"Sur toute l'exploitation"} Page={ExploitViti ? '5' : '6'}/>
      <div className='paragraphe'>Les impacts en fonction de chaque aléas est pour chaque année de 2020 à 2050 en moyenne 10 ans.</div>
      <div className='paragraphe'>Les impacts données sont pour l'ensemble de votre exploitation.</div>
      <div className='titreMoins row' style={{marginBottom:'20px'}}> Gel</div>
      <div className='row'>
        <div style={{width:'50%'}}><GraphAleasRapport myDataMoy2D={dataMoy_GEL_2D} myDataMax2D={dataMax_GEL_2D} myDataMin2D={dataMin_GEL_2D} myDataQ12D={dataQ1_GEL_2D} myDataQ32D={dataQ3_GEL_2D} myDataMoy4D={dataMoy_GEL_4D} myDataMax4D={dataMax_GEL_4D} myDataMin4D={dataMin_GEL_4D} myDataQ14D={dataQ1_GEL_4D} myDataQ34D={dataQ3_GEL_4D} aleas={'GEL'} print={true}/></div>
        <div style={{width:'50%'}}>{dataNbjoursMoy_GEL_2D !== undefined && dataNbjoursMoy_GEL_4D !== undefined && dataNbjoursSansRisque_GEL_2D !== undefined && dataNbjoursSansRisque_GEL_4D !== undefined ? <GraphAleasNBjoursRapport myDatasousMoy2={dataNbjoursMoy_GEL_2D} myDataSansRisque2={dataNbjoursSansRisque_GEL_2D} myDatasousMoy4={dataNbjoursMoy_GEL_4D} myDataSansRisque4={dataNbjoursSansRisque_GEL_4D} aleas={'GEL'} print={true}/> : ""} </div>
      </div>
      
      <div className='titreMoins row' style={{marginBottom:'20px', marginTop:'40px'}}> Coup de chaleur</div>
      <div className='row'>
        <div style={{width:'50%'}}><GraphAleasRapport myDataMoy2D={dataMoy_CDC_2D} myDataMax2D={dataMax_CDC_2D} myDataMin2D={dataMin_CDC_2D} myDataQ12D={dataQ1_CDC_2D} myDataQ32D={dataQ3_CDC_2D} myDataMoy4D={dataMoy_CDC_4D} myDataMax4D={dataMax_CDC_4D} myDataMin4D={dataMin_CDC_4D} myDataQ14D={dataQ1_CDC_4D} myDataQ34D={dataQ3_CDC_4D} aleas={'CDC'} print={true}/></div>
        <div style={{width:'50%'}}>{dataNbjoursMoy_CDC_2D !== undefined && dataNbjoursMoy_CDC_4D !== undefined && dataNbjoursSansRisque_CDC_2D !== undefined && dataNbjoursSansRisque_CDC_4D !== undefined ? <GraphAleasNBjoursRapport myDatasousMoy2={dataNbjoursMoy_CDC_2D} myDataSansRisque2={dataNbjoursSansRisque_CDC_2D} myDatasousMoy4={dataNbjoursMoy_CDC_4D} myDataSansRisque4={dataNbjoursSansRisque_CDC_4D} aleas={'CDC'} print={true}/> : ""} </div>
      </div>
      {/*<div className='commentaireRapport'>
        <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
        <div>Mettre le commentaire ici</div>
      </div> */}
    </div>
  )
}

export default PageAleasPDF2