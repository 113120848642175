import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphAleasNBjoursRapport({myDatasousMoy2, myDataSansRisque2, myDatasousMoy4, myDataSansRisque4, print}) { 
    const min2 = Math.min(...myDataSansRisque2)
    const min4 = Math.min(...myDataSansRisque4)
    const mintot = min2 < min4 ? min2 : min4
    const min = mintot <20 ? 0 : Math.floor((mintot - 20) / 10) * 10

    const tresRisque2 = [100- myDatasousMoy2[0], 100- myDatasousMoy2[1], 100- myDatasousMoy2[2], 100- myDatasousMoy2[3], 100- myDatasousMoy2[4], 100- myDatasousMoy2[5], 100- myDatasousMoy2[6]]
    const sousmoy2 = [myDatasousMoy2[0]-myDataSansRisque2[0], myDatasousMoy2[1]-myDataSansRisque2[1], myDatasousMoy2[2]-myDataSansRisque2[2], myDatasousMoy2[3]-myDataSansRisque2[3], myDatasousMoy2[4]-myDataSansRisque2[4], myDatasousMoy2[5]-myDataSansRisque2[5], myDatasousMoy2[6]-myDataSansRisque2[6]]
    const tresRisque4 = [100- myDatasousMoy4[0], 100- myDatasousMoy4[1], 100- myDatasousMoy4[2], 100- myDatasousMoy4[3], 100- myDatasousMoy4[4], 100- myDatasousMoy4[5], 100- myDatasousMoy4[6]]
    const sousmoy4 = [myDatasousMoy4[0]-myDataSansRisque4[0], myDatasousMoy4[1]-myDataSansRisque4[1], myDatasousMoy4[2]-myDataSansRisque4[2], myDatasousMoy4[3]-myDataSansRisque4[3], myDatasousMoy4[4]-myDataSansRisque4[4], myDatasousMoy4[5]-myDataSansRisque4[5], myDatasousMoy4[6]-myDataSansRisque4[6]]

    function divideDataByTen(data) {
      // Diviser chaque élément du tableau par 10
      var newData = data.map(item => item / 10);
  
      return newData;
    }
    const getOptions = () => {
      let options = {
        grid: {
          top: '22%',
          left:'11%',
          right:'13%'
        },
        legend: {
          data: ['Année sans risque à 2°C', "Année avec accident sous la moyenne à 2°C",  'Année très risquée à 2°C',  'Année sans risque à 4°C', "Année avec accident sous la moyenne à 4°C", 'Année très risquée à 4°C'],
          textStyle: {
            fontSize: 14, // Ajustez la taille de la police de la légende selon vos besoins
          },
        },
        xAxis: {
          type: 'category',
          data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
          name: 'Risques',
          nameTextStyle: {
            fontSize: 14 // Ajustez la taille de la police selon vos besoins
          },
          axisLabel: {
            fontSize: 14, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            
          }
        },
        yAxis: {
          type: 'value',
          name: "Nb d'année sur 10",
          min: min/10,
          nameTextStyle: {
            fontSize: 14 // Ajustez la taille de la police selon vos besoins
          },
          axisLabel: {
            fontSize: 14// Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
          }
        },
        series: [
          {
            name: 'Année sans risque à 2°C',
            data: divideDataByTen(myDataSansRisque2),
            type: 'bar',
            stack: 'firststack', // Définir stack sur true
            itemStyle: {
              color: '#74CE00'
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
          {
            name: 'Année avec accident sous la moyenne à 2°C',
            data: divideDataByTen(sousmoy2),
            type: 'bar',
            stack: 'firststack', // Définir stack sur true
            itemStyle: {
              color: '#E7DE1F',
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
          {
            name: 'Année très risquée à 2°C',
            data: divideDataByTen(tresRisque2),
            type: 'bar',
            stack: 'firststack', // Définir stack sur true
            itemStyle: {
              color: 'red',
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
          {
            name: 'Année sans risque à 4°C',
            data: divideDataByTen(myDataSansRisque4),
            type: 'bar',
            stack: 'secondstack', // Définir stack sur true
            itemStyle: {
              color: '#60AB00',
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
          {
            name: 'Année avec accident sous la moyenne à 4°C',
            data: divideDataByTen(sousmoy4),
            type: 'bar',
            stack: 'secondstack', // Définir stack sur true
            itemStyle: {
              color: '#ADA500',
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
          {
            name: 'Année très risquée à 4°C',
            data: divideDataByTen(tresRisque4),
            type: 'bar',
            stack: 'secondstack', // Définir stack sur true
            itemStyle: {
              color: '#AD0300',
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data.toFixed(1)
                },
                position:'right',
                textStyle: {
                  fontSize: 14 // Ajustez la taille de police selon vos préférences
                },
              },
            },
          },
        ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:print === true ? "10px" : "20px", width: print === true ? '65%' : '80%', height: print === true ? '550px' :  '450px', marginTop:print === true ? '10px' : '30px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphAleasNBjoursRapport;
  