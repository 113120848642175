import React, {useState, useRef, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import apiLink from '../../config.js'

import PRINT from '../../assets/imprimante.png'
import Entete from '../MEP/entete'
import PageDeGarde from './1_page_de_garde';
import PageHow from './2_how';
import PageProduction from './3_production';
import PageRiskRDT from './4_risk_rdt';
import PageRiskRDTCarte from './5_risk_rdt_carte';
import PageRiskPrix from './6_risk_prix';
import PageResistance1 from './7_resistance1';
import PageRistance2 from './8_resistance2';
import Page20302050 from './6_2030_2050'
import PageAleasPDF from './7_aleas_climatiques'
import PageAleasPDF2 from './8_aleas_climatiques2'
import PageSolCepage from './9-1_Solutions-cepage.js'
import PageAFOM from './9-2_AFOM.js'
import PageOpti from './9_opti';
import Couv4eme from './10_4eme_couv';

function RapportPDF() {
    const { state } = useLocation();
    const { idExploit } = state;

    const [vitiPres, setVitiPres] = useState(false)
    const [queViti, setQueViti] = useState(false)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'rapport_DiagoRisk',
    })
    function fetchData() {
        fetch(apiLink + 'page/production-rapport/' + idExploit)
        .then( (response) => {
        return response.json()
        })
        .then(response =>{
        let data = response
        for (var cle in data) {
            if (cle.includes("Vignes")) {
                setVitiPres(true)
            }
        }
        let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
        if (allKeysContainVignes) {
            setQueViti(true);
        } else {
            setQueViti(false);
        }
        })
    }

    useEffect ( () => {
        fetchData()
      }, [idExploit])
    
    return (
        <>
        <button className='buttonPrint' onClick={handlePrint} > <img src={PRINT} className="IconHome" alt="Bouton d'impression"/></button>
        <div ref={componentRef}>
            <Entete titre="Prévisualisation du rapport" rapport={true}/>

            <div className="container containerButtonPrint" style={{marginBottom:'0px'}}>
                <PageDeGarde idExploit={idExploit}/>
                <PageProduction idExploit={idExploit}/>
                <PageRiskRDT idExploit={idExploit}/>
                <PageRiskRDTCarte idExploit={idExploit}/>
                <Page20302050 idExploit={idExploit}/>
                <div className={queViti? 'HideResult' : 'ShowResult'}><PageAleasPDF idExploit={idExploit}/></div>
                <PageAleasPDF2 idExploit={idExploit}/>
                {/* <PageRiskPrix idExploit={idExploit}/> 
                <PageResistance1 idExploit={idExploit}/>
                <PageRistance2 idExploit={idExploit}/>*/}
                <div className={vitiPres=== false ? 'HideResult' : 'ShowResult'}><PageSolCepage idExploit={idExploit}/></div>
                <PageAFOM idExploit={idExploit}/>
                <PageOpti idExploit={idExploit}/>
                <PageHow idExploit={idExploit}/>
                <Couv4eme/>
            </div>
        </div> 
        <button className='validation' onClick={handlePrint} style={{marginTop:'10px',marginLeft:'1%', width:'98%', marginBottom:"10px"}}> Imprimer ce rapport </button>
        </>
    )
}

export default RapportPDF